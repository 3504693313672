import React, { useContext, useEffect } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAppDispatch, useAppSelector } from "hooks"
import { DateField, FormSelectAntd, AntdButton } from "components"
import { saveEmployeeTermData, selectEmployeeTermSaving } from "store/Employee/Terms/terms.slice"
import TermContext, { TermContextProps } from "./termsContext"
import { termsInitialValues, termsValidationSchema } from "Schema"
import { EmployeeTermInterface } from "@type/employees.types"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"
import { selectSelectedEmployee } from "store/globalState.slice"
import { Typography } from "antd"

interface TermsFormHandler extends EmployeeTermInterface {}

const Form = () => {
	const dispatch = useAppDispatch()
	const isTermsSaving = useAppSelector(selectEmployeeTermSaving())
	const selected = useAppSelector(selectSelectedEmployee)
	const { payrollTerm, handleRow, payRollTerms } = useContext(TermContext) as TermContextProps

	const methods = useForm<TermsFormHandler>({
		resolver: yupResolver(termsValidationSchema),
	})
	const {
		reset,
		handleSubmit,
		setValue,
		watch,
		formState: { isDirty, isSubmitting, errors },
	} = methods
	const handleClear = (e: React.MouseEvent) => {
		e.preventDefault()
		handleRow(null)
		reset(termsInitialValues)
	}

	useEffect(() => {
		payrollTerm ? reset({ ...payrollTerm }) : reset(termsInitialValues)
	}, [payrollTerm?._id])

	const onSubmit: SubmitHandler<TermsFormHandler> = data => {
		dispatch(saveEmployeeTermData({ ...data, employee: selected as string }))
	}

	return (
		<>
			{/* <div className="ml-2 flex flex-col py-2 empty:hidden">
				<AuditTrail id={term?._id} name={Entities.EMPLOYEE_TERM} />
			</div> */}
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Typography.Title level={5}> Create/Update Term</Typography.Title>
					<div className="flex flex-col gap-y-3">
						<DateField
							label="Start Date"
							name={"startDate"}
							setValue={setValue}
							watch={watch}
							errors={errors.startDate}
						/>
						<DateField
							label={"Finish Date"}
							name={"finishDate"}
							setValue={setValue}
							watch={watch}
							errors={errors.finishDate}
						/>
						<FormSelectAntd
							disabled={!payRollTerms?.length}
							label="Payroll Term"
							name="payrollTerm"
							options={payRollTerms?.map(({ _id, name }) => ({ label: name, value: _id }))}
						/>

						<AllowedTo perform={Permissions.UPDATE_EMPLOYEE_TERMS}>
							<div className="flex gap-x-2">
								<AntdButton className="min-w" onClick={handleClear}>
									Clear
								</AntdButton>
								<AntdButton
									className="min-w"
									loading={isTermsSaving}
									disabled={!isDirty || isSubmitting}
									htmlType="submit"
								>
									{payrollTerm ? "Update" : "Save"}
								</AntdButton>
							</div>
						</AllowedTo>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default Form
