import { useEffect, useMemo, useRef, useState } from "react"
import { GlobalSidebar, SidebarList, FormInputAntd, FormSelectAntd, AntdButton, FormInput } from "components"
import { FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { PBCodeInitialValues, PBCodeSchema } from "Schema"
import { PBCodeInterface } from "@type/payBill.types"
import { getPBCodeOptionsDropdownByType, PBCodeOptions, PBCodeType } from "config/constants"
import useAppSelector from "hooks/useAppSelector"
import useAppDispatch from "hooks/useAppDispatch"
import {
	getPBCodes,
	savePBCode,
	isPBCodeSaving,
	isPBCodeLoading,
	selectPBCodeById,
	selectPBCodeList,
} from "store/PayBill/PBCodes.slice"
import FormInput2 from "../../components/FormInput2"
import { caseConverters } from "../../config/utils"

interface PBCodeFormHandler extends PBCodeInterface {}

const pbcodeTypes = Object.values(PBCodeType).map(item => ({ label: caseConverters.titleCase(item), value: item }))

const PBCodes = () => {
	const [selectedPBCode, setSelectedPBCode] = useState<string | null>("")
	const dispatch = useAppDispatch()
	const loading = useAppSelector(isPBCodeLoading())
	const saving = useAppSelector(isPBCodeSaving())
	const PBCodesList = useAppSelector(selectPBCodeList)
	const PBCode = useAppSelector(selectPBCodeById(selectedPBCode))
	const isNewBeingCreated = useRef(false)
	const [usedForDropdownList, setUsedForDropdownList] = useState<{ label: any; value: PBCodeOptions }[]>([])

	const methods = useForm<PBCodeFormHandler>({ resolver: yupResolver(PBCodeSchema) })
	const {
		control,
		register,
		reset,
		handleSubmit,
		watch,
		setValue,
		formState: { isSubmitted, errors },
	} = methods

	const watchisBreakUnpaid = watch("rules.isBreakUnpaid")
	const watchType = watch("type")

	const { fields, append, remove } = useFieldArray({
		control,
		name: "rules.breakPolicy",
	})

	const sidebarPBCodes = useMemo(() => {
		return PBCodesList?.map(PBCode => ({ _id: PBCode._id, name: PBCode.name }))
	}, [saving, PBCodesList?.length, isSubmitted])

	const onSubmit: SubmitHandler<PBCodeFormHandler> = data => {
		isNewBeingCreated.current = true
		dispatch(
			savePBCode(data, id => {
				setSelectedPBCode(id)
				isNewBeingCreated.current = false
			}),
		)
	}

	useEffect(() => {
		dispatch(getPBCodes("", setSelectedPBCode))
	}, [])

	useEffect(() => {
		if (!watchisBreakUnpaid) {
			setValue("rules.breakPolicy", undefined)
		}
	}, [watchisBreakUnpaid])

	useEffect(() => {
		if (watchType) {
			setUsedForDropdownList(getPBCodeOptionsDropdownByType(watchType as PBCodeType))
		}
	}, [watchType])

	useEffect(() => {
		if (selectedPBCode !== (null && undefined)) {
			reset({ ...PBCodeInitialValues, ...PBCode })
			PBCode &&
				setUsedForDropdownList(
					getPBCodeOptionsDropdownByType((PBCode.type as PBCodeType) ?? PBCodeInitialValues.type),
				)
		} else if (selectedPBCode === null && !isNewBeingCreated.current) {
			reset({ ...PBCodeInitialValues })
			setUsedForDropdownList(getPBCodeOptionsDropdownByType(PBCodeInitialValues.type as PBCodeType))
		}
	}, [selectedPBCode])

	const handleSelected = (PBCodeId: string | null): void => setSelectedPBCode(PBCodeId)

	return (
		<>
			<GlobalSidebar>
				<SidebarList
					title={"PBCodes"}
					loading={loading}
					selected={selectedPBCode}
					setSelected={handleSelected}
					list={sidebarPBCodes}
				/>
			</GlobalSidebar>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div>
						<div className="flex flex-row gap-x-4">
							{pbcodeTypes.map(({ label, value }, i) => (
								<div key={`${value}${i}`}>
									<FormInput
										containerClass="mb-2.75 !flex-row-reverse justify-end"
										className="mr-2 !w-auto"
										register={register}
										errors={errors}
										labelClass="order-1 ml-5.5"
										type="radio"
										value={value}
										label={label}
										id={value}
										name="type"
									/>
								</div>
							))}
						</div>

						<div>
							<div className="flex flex-row gap-x-10">
								<div className="w-1/3 flex-col space-y-3">
									<div className="text-[20px] font-bold">Basic Information </div>
									<div className="space-y-3">
										<FormInputAntd label="Name" name="name" type="text" />
										<FormInputAntd label="Description" name="description" type="textarea" />
										<FormInputAntd label="Rate per hour" name="ratePerHour" type="number" min={0} />
										<FormSelectAntd
											disabled={!usedForDropdownList.length}
											label="Select option it is to be used for"
											name="usedFor"
											options={usedForDropdownList}
										/>
									</div>
								</div>

								<div className="flex-grow flex-col space-y-3">
									<div className="text-[20px] font-bold">Rules </div>
									<div className="w-1/2">
										<div className="space-y-3">
											<FormInputAntd
												label="Bank holiday multiplier"
												name="rules.bankHolidayMultiplier"
												type="number"
												min={0}
											/>

											<>
												{watchType === PBCodeType.BILL && (
													<div>
														<div className="flex flex-row items-center gap-x-10">
															<FormInput2
																containerClass="flex !flex-row"
																type="checkbox"
																className="w-auto px-1"
																label="Unpaid breaks"
																register={register}
																name="rules.isBreakUnpaid"
																errors={errors}
															/>

															{watchisBreakUnpaid && (
																<AntdButton
																	type="text"
																	onClick={() => {
																		append({ breakHours: 0, shiftHours: 0 })
																	}}
																>
																	+ Add break timings
																</AntdButton>
															)}
														</div>

														<div className="max-h-50 overflow-y-scroll p-1">
															{fields.map((item, index) => (
																<div
																	className=" mt-1 flex flex-row gap-x-5"
																	key={item.id}
																>
																	<div className="flex flex-row gap-x-5">
																		<FormInputAntd
																			label="Shift hours"
																			name={`rules.breakPolicy.${index}.shiftHours`}
																			type="number"
																			defaultValue={0}
																			min={0}
																		/>

																		<FormInputAntd
																			label="Break hours"
																			name={`rules.breakPolicy.${index}.breakHours`}
																			type="number"
																			defaultValue={0}
																			min={0}
																		/>
																	</div>

																	<div className="mt-[18px] self-center">
																		<AntdButton
																			onClick={() => remove(index)}
																			className="capitalize text-black"
																		>
																			Remove
																		</AntdButton>
																	</div>
																</div>
															))}
														</div>
													</div>
												)}
											</>
										</div>
									</div>
								</div>
							</div>

							<div className="mt-5 flex space-x-3">
								<AntdButton htmlType="button" onClick={() => setSelectedPBCode(null)}>
									New
								</AntdButton>
								<AntdButton htmlType="submit" loading={saving}>
									{selectedPBCode ? "Update" : "Save"}
								</AntdButton>
							</div>
						</div>
					</div>
				</form>
			</FormProvider>
		</>
	)
}
0
export default PBCodes
