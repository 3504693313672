import LoadingBar from "react-top-loading-bar"
import { momentZ } from "../../config/moment"
import useLocations from "../../hooks/useLocations"
import useAppDispatch from "../../hooks/useAppDispatch"
import useAppSelector from "../../hooks/useAppSelector"
import { DatePicker } from "antd"
import { usePBcodes } from "../../hooks/usePBcodes"
import { AntdButton, Modal } from "components"
import { GetPBCodeInterface } from "@type/payBill.types"
import ImportPbcodesTableWrapper from "../../components/ImportPbcodesTableWrapper"
import { DATE_FORMATS, PBCodeOptions } from "../../config/constants"
import { useEffect, useMemo, useRef, useState } from "react"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import { GetAllLocationDetailsInterface } from "@type/locations.types"
import { isEmployeeLoading, selectEmployeeList } from "../../store/Employee/detail.slice"
import { EmployeePaycodeDetailInterface, GetEmployeeInterface } from "@type/employees.types"
import {
	isEmployeePbCodeDetailsSaving,
	saveEmployeesPbcodesInBulk,
} from "../../store/Employee/PBCodeDetails/pbCodeDetails.slice"
import * as XLSX from "xlsx"
import { UploadOutlined } from "@ant-design/icons"
import { toast } from "react-toastify"

interface CsvRow {
	pin: string
	sin: string
	site: string
	branch: string
	pbcode: string
	customer: string
	employee: string
	status: string
	isInvalidPin: boolean
	isInvalidSin: boolean
	isInvalidPbcode: boolean
}

interface payload {
	employee: string
	month: string
	paycodes: {
		location: string
		pbcode: string
	}[]
}

const renderStringForTable = (val: string) => <div className=" w-[180px] text-left">{val}</div>
const tableHeaders = [
	{
		label: "Pin",
		key: "pin",
		render: ({ row, key }: any) => <div>{row[key]}</div>,
	},
	{
		label: "Employee",
		key: "employee",
		render: ({ row, key }: any) => renderStringForTable(row[key]),
	},
	{
		label: "Branch",
		key: "branch",
		render: ({ row, key }: any) => renderStringForTable(row[key]),
	},
	{
		label: "Status",
		key: "status",
		render: ({ row, key }: any) => renderStringForTable(row[key]),
	},
	{
		label: "Sin",
		key: "sin",
		render: ({ row, key }: any) => <div>{row[key]}</div>,
	},
	{
		label: "Site",
		key: "site",
		render: ({ row, key }: any) => renderStringForTable(row[key]),
	},
	{
		label: "Customer",
		key: "customer",
		render: ({ row, key }: any) => renderStringForTable(row[key]),
	},
	{
		label: "Pbcode",
		key: "pbcode",
		render: ({ row, key }: any) => renderStringForTable(row[key]),
	},
]

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
}

const ImportPbcodesModal: React.FC<Props> = ({ open, modalHandler }) => {
	const [rows, setRows] = useState<Array<CsvRow>>([])
	const employeeList = useAppSelector(selectEmployeeList())
	const isEmployeeListLoading = useAppSelector(isEmployeeLoading())
	const { allLocations, fetchAllLocationsDetails, isAllLocationsLoading } = useLocations()
	const { fetchPbCodesByUsedForFilter, isLoading: isPbCodesLoading, pbCodes } = usePBcodes()
	const [isInvalidCsv, setIsInvalidCsv] = useState(false)
	const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(momentZ().endOf("day").endOf("month"))
	const isEmployeesPbcodesSaving = useAppSelector(isEmployeePbCodeDetailsSaving())
	const dispatch = useAppDispatch()
	const inputRef = useRef<HTMLInputElement>(null)

	const employeeMap = useMemo(() => {
		const map = new Map<number, GetEmployeeInterface>()
		if (employeeList.length) employeeList.forEach(item => map.set(+item.pin, item))
		return map
	}, [employeeList])

	const locationMap = useMemo(() => {
		const map = new Map<string, GetAllLocationDetailsInterface>()
		if (allLocations.length)
			allLocations.forEach(item => {
				const key = { name: item.name.toLowerCase().trim(), sin: +item.sin }
				map.set(JSON.stringify(key), item)
			})
		return map
	}, [allLocations])

	const pbcodesMap = useMemo(() => {
		const map = new Map<string, GetPBCodeInterface>()
		if (pbCodes.length) pbCodes.forEach(item => map.set(item.name.toLowerCase().trim(), item))
		return map
	}, [pbCodes])

	const handlePrev = () => {
		if (selectedDate) setSelectedDate(momentZ(selectedDate).subtract(1, "month").endOf("day").endOf("month"))
		else setSelectedDate(momentZ().subtract(1, "month").endOf("day").endOf("month"))
	}

	const handleNext = () => {
		if (selectedDate) setSelectedDate(momentZ(selectedDate).add(1, "month").endOf("day").endOf("month"))
		else setSelectedDate(momentZ().add(1, "month").endOf("day").endOf("month"))
	}

	const onSubmit = () => {
		const payload: EmployeePaycodeDetailInterface[] = []
		const pins = rows.map(item => item.pin)
		const distinctPins = [...new Set(pins)]
		distinctPins.forEach(pin => {
			let temp: payload | null = null
			const employee = employeeMap.get(+pin)
			if (employee && selectedDate) {
				temp = {
					employee: employee._id,
					month: selectedDate.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
					paycodes: [],
				}
			}
			rows.forEach(item => {
				if (pin === item.pin) {
					const location = locationMap.get(
						JSON.stringify({ name: item.site.toLowerCase().trim(), sin: +item.sin }),
					)
					const pbcode = pbcodesMap.get(item.pbcode.toLowerCase().trim())
					if (temp && selectedDate && location && pbcode)
						temp.paycodes.push({ pbcode: pbcode._id, location: location._id })
				}
			})
			if (temp && selectedDate) payload.push(temp)
		})
		if (payload.length)
			dispatch(
				saveEmployeesPbcodesInBulk({ payload }, (success: boolean) => {
					if (success) {
						setRows([])
						setSelectedDate(momentZ().endOf("day").endOf("month"))
						modalHandler(false)
						toast.success("Paycode details uploaded and saved successfully")
					} else toast.error("Unkown error occured")
				}),
			)
	}

	const readAndSetXlsxFileData = (rows: unknown[]) => {
		setIsInvalidCsv(false)

		const mapedRows = rows.map((item: any) => {
			const data = {
				pin: item?.Pin,
				employee: item?.Employee,
				branch: item?.Branch,
				status: item?.Status,
				sin: item?.Sin,
				site: item?.Site,
				customer: item?.Customer,
				pbcode: item["PB Code"],
				isInvalidPin: false,
				isInvalidSin: false,
				isInvalidPbcode: false,
			}
			const isPinVerified = employeeMap.has(+data?.pin)
			const isSinVerified = locationMap.has(
				JSON.stringify({ name: data?.site?.toLowerCase().trim(), sin: +data?.sin }),
			)
			const isPbcodeVerified = pbcodesMap.has(data?.pbcode?.toLowerCase().trim())
			if (!isPinVerified) {
				setIsInvalidCsv(true)
				data.isInvalidPin = true
			}
			if (!isSinVerified) {
				setIsInvalidCsv(true)
				data.isInvalidSin = true
			}
			if (!isPbcodeVerified) {
				setIsInvalidCsv(true)
				data.isInvalidPbcode = true
			}
			return data
		})
		setRows(mapedRows)
	}

	const handleFileUpload = (event: any) => {
		const file = event.target.files[0]
		const reader = new FileReader()

		reader.onload = e => {
			if (e.target) {
				const binaryStr = e.target.result
				const workbook = XLSX.read(binaryStr, { type: "binary" })
				const sheetName = workbook.SheetNames[0]
				const sheet = workbook.Sheets[sheetName]
				const jsonData = XLSX.utils.sheet_to_json(sheet)
				readAndSetXlsxFileData(jsonData)
			}
		}
		reader.readAsArrayBuffer(file)
	}

	useEffect(() => {
		fetchAllLocationsDetails()
		fetchPbCodesByUsedForFilter(PBCodeOptions.EMPLOYEE)
	}, [])

	const onCloseModal = (open: boolean) => {
		setRows([])
		setSelectedDate(momentZ().endOf("day").endOf("month"))
		modalHandler(open)
	}

	return (
		<Modal
			open={open}
			title={<div className="font-semi text-center text-lg font-semibold">Import Pbcodes For All Employees</div>}
			modalHandler={onCloseModal}
			containerClass="3xl:min-h-[850px] h-[600px] mx-20 w-full"
		>
			{isEmployeeListLoading || isAllLocationsLoading || isPbCodesLoading ? (
				<LoadingBar />
			) : (
				<>
					<div className="mb-2 flex h-auto flex-row justify-between">
						<div className="flex flex-row gap-x-1">
							<div>
								<input
									ref={inputRef}
									type="file"
									accept=".xlsx, .xls, .numbers, .csv"
									className="hidden"
									name="link"
									onChange={handleFileUpload}
								/>

								<AntdButton
									disabled={
										isEmployeeListLoading ||
										isAllLocationsLoading ||
										isPbCodesLoading ||
										isEmployeesPbcodesSaving
									}
									name="link"
									icon={<UploadOutlined />}
									onClick={e => {
										e.preventDefault()
										if (inputRef && inputRef.current) {
											inputRef.current.click()
										}
									}}
								>
									Import Excel
								</AntdButton>
							</div>
						</div>

						<div className="ml-auto flex  gap-x-2">
							<AntdButton onClick={handlePrev} shape="circle" icon={<MdChevronLeft size={15} />} />
							<DatePicker
								style={{ border: "solid slate", height: "35px", minWidth: "280px" }}
								picker="month"
								placeholder="Select Month"
								value={selectedDate}
								disabledDate={current => {
									return current && current > momentZ().endOf("month")
								}}
								onChange={value => {
									if (value) setSelectedDate(momentZ(value).endOf("day").endOf("month"))
									else setSelectedDate(null)
								}}
							/>
							<AntdButton
								disabled={(selectedDate && selectedDate.isSame(momentZ(), "month")) ?? true}
								onClick={handleNext}
								shape="circle"
								icon={<MdChevronRight size={15} />}
							></AntdButton>
						</div>
					</div>

					<div className="flex flex-col">
						<div className="h-[450px] overflow-y-scroll">
							<ImportPbcodesTableWrapper
								tableHeaders={tableHeaders}
								rows={rows}
								isLoading={isEmployeeListLoading || isAllLocationsLoading || isPbCodesLoading}
							/>
						</div>

						<div className="w-150px ml-auto mt-2">
							<AntdButton
								className=""
								disabled={isInvalidCsv || !rows.length || !selectedDate || isEmployeesPbcodesSaving}
								onClick={onSubmit}
								loading={isEmployeesPbcodesSaving}
							>
								Submit
							</AntdButton>
						</div>
					</div>
				</>
			)}
		</Modal>
	)
}
export default ImportPbcodesModal
