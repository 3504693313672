import LocationsService from "../services/locations/locationDetails.service"
import { GetAllLocationDetailsInterface, GetLocationDetailsInterface } from "@type/locations.types"
import { useState } from "react"

const useLocations = () => {
	const [locationsByBranch, setLocationsByBranch] = useState<GetLocationDetailsInterface[]>([])
	const [isLocationsByBranchLoading, setisLocationsByBranchLoading] = useState(false)
	const [allLocations, setAllLocations] = useState<GetAllLocationDetailsInterface[]>([])
	const [isAllLocationsLoading, setIsAllLocationsLoading] = useState(false)

	const fetchLocationsByBranch = async (branch: string) => {
		try {
			setisLocationsByBranchLoading(true)
			const { data: locationResponse } = await LocationsService.getLocationByBranchId(branch)
			setLocationsByBranch(locationResponse)
			setisLocationsByBranchLoading(false)
		} catch {
			setisLocationsByBranchLoading(false)
		}
	}

	const fetchAllLocationsDetails = async () => {
		try {
			setIsAllLocationsLoading(true)
			const { data: locationResponse } = await LocationsService.getAllLocationsDetail()
			setAllLocations(locationResponse)
			setIsAllLocationsLoading(false)
		} catch {
			setIsAllLocationsLoading(false)
		}
	}

	return {
		allLocations,
		fetchAllLocationsDetails,
		locationsByBranch,
		isAllLocationsLoading,
		fetchLocationsByBranch,
		isLocationsByBranchLoading,
	}
}

export default useLocations
