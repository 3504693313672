import { EmployeePaycodeDetailInterface, GetEmployeePaycodeDetailParams } from "@type/employees.types"
import axios from "axios"

const ROOT_PATH = "employees/paycode-details"

export default class EmployeePbCodesService {
	static createPaycodeDetails = (employeePbCodes: EmployeePaycodeDetailInterface) =>
		axios.post<EmployeePaycodeDetailInterface>(ROOT_PATH, employeePbCodes)

	static getPaycodeDetails = ({ employee, month }: GetEmployeePaycodeDetailParams) =>
		axios.get<EmployeePaycodeDetailInterface>(`${ROOT_PATH}`, {
			params: {
				employee,
				month,
			},
		})

	static updatePaycodeDetails = (employeePbCodeId: string, employeePbCodeData: EmployeePaycodeDetailInterface) =>
		axios.put<EmployeePaycodeDetailInterface>(`${ROOT_PATH}/${employeePbCodeId}`, employeePbCodeData)

	static createOrUpdateInBulk = (data: { payload: EmployeePaycodeDetailInterface[] }) =>
		axios.post<{ message: string }>(`${ROOT_PATH}/bulk`, data)
}
