import * as Yup from "yup"

const branchInitialValues = {
	name: "",
	shortName: "",
	addressLine1: "",
	addressLine2: "",
	city: "",
	county: "",
	postalCode: "",
	telephone: "",
	fax: "",
	publicHolidaySchedule: "",
	lockDate: "",
	enabled: true,
	requiredQualifications: [],
}

const branchValidationSchema = Yup.object().shape({
	name: Yup.string().required().label("Name"),
	organization: Yup.string().required().label("Organization"),
	shortName: Yup.string().required().label("Shirt Name"),
	addressLine1: Yup.string().label("Address Line1"),
	addressLine2: Yup.string().label("Address Line2"),
	city: Yup.string().label("City"),
	county: Yup.string().label("County"),
	postalCode: Yup.string().label("Postal Code"),
	telephone: Yup.string().label("Telephone"),
	fax: Yup.string().label("Fax"),
	publicHolidaySchedule: Yup.string().label("Public Holiday Schedule").nullable(),
	lockDate: Yup.string().label("Branch Lock Date").optional(),
	enabled: Yup.boolean().label("Enabled"),
	requiredQualifications: Yup.array(Yup.string()).label("Required Qualifications"),
})

export { branchInitialValues, branchValidationSchema }
