import * as Yup from "yup"

export const SDSuppliersInitialValues = {
	name: "",
	PBCode: "",
	enabled: true,
}

export const SDSuppliersSchema = Yup.object().shape({
	name: Yup.string().required().label("Name"),
	enabled: Yup.boolean().label("Enabled"),
	organization: Yup.string().required().label("Organization"),
})
