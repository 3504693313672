import Form from "./Form"
import TermsTable from "./TermsTable"
import TermContext from "./termsContext"
import { Typography } from "antd"
import { useEffect, useState } from "react"
import { PayRollTermsInterface } from "@type/payBill.types"
import { selectSelectedEmployee } from "store/globalState.slice"
import { getEmployeesTermByEmployeeId } from "store/Employee/Terms/terms.slice"
import { useAppDispatch, useAppSelector } from "hooks"
import { getPayrollTerms, isPayrollTermLoading, selectPayrollTermList } from "../../../store/PayBill/payRollTerms.slice"

const Terms = () => {
	const dispatch = useAppDispatch()
	const selected = useAppSelector(selectSelectedEmployee)
	const [payrollTerm, setPayRollTerm] = useState<PayRollTermsInterface | null>(null)
	const payRollTerms = useAppSelector(selectPayrollTermList)
	const payrollTermsLoading = useAppSelector(isPayrollTermLoading())

	useEffect(() => {
		selected && dispatch(getEmployeesTermByEmployeeId(selected))
	}, [selected])

	useEffect(() => {
		dispatch(getPayrollTerms())
	}, [])

	const handleRow = (e: any) => setPayRollTerm(e)

	return (
		<TermContext.Provider value={{ handleRow, payrollTerm, payRollTerms, payrollTermsLoading }}>
			<div className="grid grid-cols-12 gap-x-10">
				<div className="col-span-3">
					<Form />
				</div>
				<div className="col-span-9">
					<Typography.Title level={5}>All Terms</Typography.Title>
					<TermsTable />
				</div>
			</div>
		</TermContext.Provider>
	)
}

export default Terms
