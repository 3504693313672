import { HoursType, RateType, RuleType, PBCodeOptions, PBCodeType } from "config/constants"
import * as Yup from "yup"
export const PBCodeInitialValues = {
	name: "",
	description: "",
	ratePerHour: 0,
	usedFor: PBCodeOptions.EMPLOYEE,
	type: PBCodeType.PAY,
	rules: {
		bankHolidayMultiplier: 0,
		isBreakUnpaid: false,
	},
}

export const PBCodeSchema = Yup.object().shape({
	name: Yup.string().required().label("Name"),
	description: Yup.string().required().label("Description"),
	ratePerHour: Yup.number().required().label("Rate per hour"),
	usedFor: Yup.string().required().label("Used For"),
	type: Yup.string().required().label("Type"),
	rules: Yup.object({
		bankHolidayMultiplier: Yup.number().required("Bank holiday"),
		isBreakUnpaid: Yup.bool().required("Allow unpaid breaks"),
	}),
})

export const PBRuleInitialValues = {
	name: "",
	description: "",
	type: RuleType.PAY,
}

export const PBRuleSchema = Yup.object().shape({
	name: Yup.string().required().label("Name"),
	description: Yup.string().required().label("Description"),
	type: Yup.string().required().label("PB Code"),
})

export const PBRuleDetailsInitialValues = {
	PBRule: "",
	startDate: "",
	rate: 0,
	fixedHours: 0,
	externalCode: "",
	hoursType: HoursType.NORM,
	PBCode: PBCodeOptions.CONTRACT,
	rateType: RateType.HOURLY,
	ruleText: "",
	applicableDays: {
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
		saturday: false,
		sunday: false,
	},
	from: 0,
	to: 0,
	activateRuleHours: 0,
	isForBilling: false,
	requiresApproval: false,
	causesCheckFailure: false,
	useGreatestAmount: false,
}

export const PBRuleDetailsSchema = Yup.object().shape({
	PBRule: Yup.string().required().label("PBRule"),
	startDate: Yup.string().required().label("Start Date"),
	rate: Yup.number().required().label("Rate").typeError("must be a number"),
	fixedHours: Yup.number().required().label("Fixed Hours").typeError("must be a number"),
	externalCode: Yup.string().required().label("External Code"),
	hoursType: Yup.string().required().label("Hours Type"),
	PBCode: Yup.string().required().label("PBCode"),
	rateType: Yup.string().required().label("Rate Type"),
	ruleText: Yup.string().required().label("Rule Text"),
	applicableDays: Yup.object().shape({
		monday: Yup.boolean().optional().label("Mon"),
		tuesday: Yup.boolean().optional().label("Tue"),
		wednesday: Yup.boolean().optional().label("Wed"),
		thursday: Yup.boolean().optional().label("Thu"),
		friday: Yup.boolean().optional().label("Fri"),
		saturday: Yup.boolean().optional().label("Sat"),
		sunday: Yup.boolean().optional().label("Sun"),
	}),
	from: Yup.number().required().label("from").typeError("must be a number"),
	to: Yup.number().required().label("to").typeError("must be a number"),
	activateRuleHours: Yup.number().required().label("Activate rule hours").typeError("must be a number"),
	isForBilling: Yup.boolean().required().label("For Billing"),
	requiresApproval: Yup.boolean().required().label("Requires Approval"),
	causesCheckFailure: Yup.boolean().required().label("Causes a Check Failure"),
	useGreatestAmount: Yup.boolean().required().label("Use Greatest Amount"),
})

export const holidaysInitialValues = {
	name: "",
	date: "",
	PBCode: PBCodeOptions.CONTRACT,
}

export const publicHolidaysInitialValues = {
	name: "",
	holiday: holidaysInitialValues,
}

export const publicHolidaysSchema = Yup.object().shape({
	name: Yup.string().required().label("Region Name"),
	holiday: Yup.lazy(value => {
		if (value && Object.values(value)?.some(v => !(v === null || v === undefined || v === ""))) {
			return Yup.object({
				name: Yup.string().notRequired().label("Holiday Name"),
				date: Yup.string().notRequired().label("Date"),
				PBCode: Yup.string().notRequired().label("PBCode"),
			})
		}
		return Yup.mixed().notRequired()
	}),
})

export const PBTermInitialValues = {
	name: "",
	description: "",
}

export const PBTermSchema = Yup.object().shape({
	name: Yup.string().required().label("Name"),
	description: Yup.string().required().label("Description"),
})

export const PBTermDetailsInitialValues = {
	PBTerm: "",
	startDate: "",
	PBCode: PBCodeOptions.CONTRACT,
	dailyOt1Hours: 0,
	dailyOt2Hours: 0,
	periodOt1Hours: 0,
	periodOt2Hours: 0,
	periodBaseDate: "",
	periodDays: 0,
	restPeriodHours: 0,
}

export const PBTermDetailsSchema = Yup.object().shape({
	PBTerm: Yup.string().required().label("PBTerm"),
	startDate: Yup.string().required().label("Start Date"),
	PBCode: Yup.string().required().label("PB Code"),
	dailyOt1Hours: Yup.number().required().label("Daily OT1 Hours").typeError("must be a number"),
	dailyOt2Hours: Yup.number().required().label("Daily OT2 Hours").typeError("must be a number"),
	periodOt1Hours: Yup.number().required().label("Period OT1 Hours").typeError("must be a number"),
	periodOt2Hours: Yup.number().required().label("Period OT2 Hours").typeError("must be a number"),
	periodBaseDate: Yup.string().required().label("Period Base Date"),
	periodDays: Yup.number().required().label("Period Days").typeError("must be a number"),
	restPeriodHours: Yup.number().required().label("Rest Period Hours").typeError("must be a number"),
})
