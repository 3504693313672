import React from "react"
import cx from "classnames"
import { twMerge } from "tailwind-merge"
import Icon from "./Icon"
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string
	labelClass?: string
	loading?: boolean
	className?: string
	loadingClass?: string
	loaderSize?: number
	autoComplete?: string
}

const Input2 = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			className = "",
			labelClass = "",
			label = null,
			loading = false,
			loadingClass = "",
			loaderSize = 25,
			autoComplete,
			...rest
		},
		ref,
	) => {
		const inputClassName = twMerge(
			"px-3 py-3.5 w-input outline-none rounded-lg border-0.5 border-black hover:border-dark text-black placeholder:text-secondary-dark text-md duration-100 ease-in-out",
			className,
		)

		return (
			<>
				{label && <label className={cx("mb-2 font-medium text-black", labelClass)}>{label}</label>}
				<input autoComplete={autoComplete} className={`${inputClassName}`} ref={ref} {...rest} />
				{loading && (
					<Icon
						name="spinner"
						color="black"
						size={loaderSize}
						className={`absolute bottom-4 right-7 animate-spin ${loadingClass}`}
					/>
				)}
			</>
		)
	},
)

export default Input2
