import { employeeWorkHistoryService } from "../services/index"
import { EmployeeWorkHistoryInterface } from "@type/employees.types"
import { useState } from "react"

const useEmployeeWorkHistory = () => {
	const [employeeWorkHistoryForDuration, setEmployeeWorkHistoryForDuration] = useState<
		EmployeeWorkHistoryInterface[]
	>([])
	const [isEmployeeWorkHistoryForDurationLoading, setIsEmployeeWorkHistoryForDurationLoading] = useState(false)

	const fetchEmployeeWorkHistoryForDuration = async (employee: string, startDate: string, endDate: string) => {
		try {
			setIsEmployeeWorkHistoryForDurationLoading(true)
			const { data: employeeWorkHistoryForDurationResponse } =
				await employeeWorkHistoryService.getEmployeeWorkHistoryForDurationByEmployee(
					employee,
					startDate,
					endDate,
				)
			setEmployeeWorkHistoryForDuration(employeeWorkHistoryForDurationResponse)
			setIsEmployeeWorkHistoryForDurationLoading(false)
		} catch {
			setIsEmployeeWorkHistoryForDurationLoading(false)
		}
	}

	return {
		fetchEmployeeWorkHistoryForDuration,
		employeeWorkHistoryForDuration,
		isEmployeeWorkHistoryForDurationLoading,
	}
}

export default useEmployeeWorkHistory
