import EmployeePbCodesService from "../../../services/employees/pbCodeDetails"
import { AppThunk, RootState } from "store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeePaycodeDetailInterface } from "@type/employees.types"

export interface PbCodeDetailsStateInterface {
	data: null | EmployeePaycodeDetailInterface
	loading: boolean
	saving: boolean
}

const initialState: PbCodeDetailsStateInterface = {
	data: null,
	loading: false,
	saving: false,
}

const employeePbCodeDetailsSlice = createSlice({
	name: "pbCodeDetails",
	initialState,
	reducers: {
		requestPbCodeDetailsData: pbCodeDetails => {
			pbCodeDetails.loading = true
		},
		receivePbCodeDetailsData: (pbCodeDetails, action: PayloadAction<EmployeePaycodeDetailInterface>) => {
			pbCodeDetails.loading = false
			pbCodeDetails.data = action.payload
		},
		receivePbCodeDetailsDataFailed: pbCodeDetails => {
			pbCodeDetails.loading = false
		},
		requestSavingPbCodeDetailsData: pbCodeDetailsData => {
			pbCodeDetailsData.saving = true
		},
		SavingEmployeePbCodeDetailsSuccess: (pbCodeDetails, action: PayloadAction<EmployeePaycodeDetailInterface>) => {
			pbCodeDetails.saving = false
			pbCodeDetails.data = action.payload
		},
		SavingEmployeePbCodeDetailsSuccessFail: pbCodeDetails => {
			pbCodeDetails.saving = false
		},
		SavingEmployeePbCodeDetailsSuccessPassed: pbCodeDetails => {
			pbCodeDetails.saving = false
		},
	},
})

//REDUCER
export default employeePbCodeDetailsSlice.reducer

//ACTIONS
const {
	receivePbCodeDetailsData,
	requestPbCodeDetailsData,
	receivePbCodeDetailsDataFailed,
	requestSavingPbCodeDetailsData,
	SavingEmployeePbCodeDetailsSuccess,
	SavingEmployeePbCodeDetailsSuccessFail,
	SavingEmployeePbCodeDetailsSuccessPassed,
} = employeePbCodeDetailsSlice.actions

const getPbCodeDetailsByEmployeeAndMonth =
	(employee: string, month: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestPbCodeDetailsData())
			const { data: pbCodeDetailsResponse } = await EmployeePbCodesService.getPaycodeDetails({
				employee,
				month,
			})
			dispatch(receivePbCodeDetailsData(pbCodeDetailsResponse))
		} catch (error) {
			dispatch(receivePbCodeDetailsDataFailed())
		}
	}

const saveEmployeepbCodeDetailsData =
	(pbCodeDetailsData: EmployeePaycodeDetailInterface): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingPbCodeDetailsData())
			if (pbCodeDetailsData._id) {
				data = await EmployeePbCodesService.updatePaycodeDetails(pbCodeDetailsData._id, pbCodeDetailsData)
			} else {
				data = await EmployeePbCodesService.createPaycodeDetails(pbCodeDetailsData)
			}
			const { data: pbCodeDetailsResponse } = data
			dispatch(SavingEmployeePbCodeDetailsSuccess(pbCodeDetailsResponse))
		} catch (error) {
			dispatch(SavingEmployeePbCodeDetailsSuccessFail())
		}
	}

const saveEmployeesPbcodesInBulk =
	(data: { payload: EmployeePaycodeDetailInterface[] }, cb?: (success: boolean) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestSavingPbCodeDetailsData())

			if (data.payload.length) {
				const batchSize = 5
				const batches = []

				for (let i = 0; i < data.payload.length; i += batchSize) {
					const batch = data.payload.slice(i, i + batchSize)
					batches.push(batch)
				}
				await Promise.all(batches.map(batch => EmployeePbCodesService.createOrUpdateInBulk({ payload: batch })))
				dispatch(SavingEmployeePbCodeDetailsSuccessPassed())
				cb?.(true)
			}
		} catch (error) {
			dispatch(SavingEmployeePbCodeDetailsSuccessFail())
			cb?.(false)
		}
	}

export { getPbCodeDetailsByEmployeeAndMonth, saveEmployeepbCodeDetailsData, saveEmployeesPbcodesInBulk }

//SELECTORS
const selectEmployeePbCodeDetailsState = (state: RootState) => state.employee.pbCodeDetails
const isEmployeePbCodeDetailsLoading = () => (state: RootState) => selectEmployeePbCodeDetailsState(state).loading
const selectEmployeePbCodeDetailsData = () => (state: RootState) => selectEmployeePbCodeDetailsState(state).data
const isEmployeePbCodeDetailsSaving = () => (state: RootState) => selectEmployeePbCodeDetailsState(state).saving

export {
	isEmployeePbCodeDetailsSaving,
	isEmployeePbCodeDetailsLoading,
	selectEmployeePbCodeDetailsData,
	selectEmployeePbCodeDetailsState,
}
