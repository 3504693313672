import { GetPBCodeInterface } from "@type/payBill.types"
import { useState } from "react"
import { PBCodeOptions } from "../config/constants"
import { PBCodesService } from "../services/payBill"

export const usePBcodes = () => {
	const [pbCodes, setPbCodes] = useState<GetPBCodeInterface[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchPbCodesByUsedForFilter = async (usedFor: PBCodeOptions) => {
		try {
			setIsLoading(true)
			const { data: pbCodeResponse } = await PBCodesService.getAllPBCodes(usedFor)
			setPbCodes(pbCodeResponse)
			setIsLoading(false)
		} catch {
			setIsLoading(false)
		}
	}

	return { pbCodes, isLoading, fetchPbCodesByUsedForFilter }
}
