import PBCodes from "./PBCodes"
import { useAbac } from "react-abac"
import useFeatureFlags from "hooks/useFeatureFlags"
import { FeatureFlagEnums } from "types/featureFlags.type"
import { useMemo, useState } from "react"
import { Divider, Segmented } from "antd-v5"

const Index = () => {
	const { userHasPermissions } = useAbac()
	const { featureFlagEnabled } = useFeatureFlags()
	const [value, setValue] = useState<string>("PB Codes")
	const options = useMemo(() => {
		return [
			{
				label: "PB Codes",
				perform: [],
				featureName: FeatureFlagEnums.ADMIN_PAYBILL_PB_CODES,
				component: <PBCodes />,
			},
			// {
			// 	label: "PB Terms",
			// 	perform: [],
			// 	featureName: FeatureFlagEnums.ADMIN_PAYBILL_PB_CODES,
			// 	component: <PBTerms />,
			// },
		]
			.map(item => ({ ...item, value: item.label }))
			.filter(item => userHasPermissions(item.perform) && featureFlagEnabled(item.featureName))
	}, [])
	return (
		<div>
			<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
			<div className="flex items-center justify-start">
				<Segmented options={options as any} value={value} onChange={(e: any) => setValue(e)} />
			</div>
			<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
			<div className="px-2">{options?.find(item => item.value === value)?.component}</div>
		</div>
	)
}

export default Index
