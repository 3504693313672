import * as Yup from "yup"

export const SDQualificationInitialValues = {
	name: "",
	canBeOverridden: false,
}

export const SDQualificationSchema = Yup.object().shape({
	name: Yup.string().required().label("Name"),
	canBeOverridden: Yup.boolean().required().label("Can be Overridden"),
})
