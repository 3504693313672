import React from "react"
import cx from "classnames"
import { twMerge } from "tailwind-merge"
interface RenderProps {
	row: any
	key: string
}

export interface TableColumnProps {
	label: string
	key: string
	keyForSorting?: string
}
export interface TableColumnExtendedProps extends TableColumnProps {
	render?: (data: RenderProps) => React.ReactNode
	headerRender?: (data: TableColumnProps) => React.ReactNode
	className?: string
}
export interface props {
	columns: TableColumnExtendedProps[]
	rows: any[] | any
	onRowClick?: (id: string) => void
	selectRow?: boolean
	selectedRowColor?: string
	selectedRows?: string[]
	rowClassName?: string
	headerClassName?: string
	headerRowClassName?: string
	tableClassName?: string
	checkInvalidRow?(row: any): boolean | undefined
	invalidRowColor?: string
	sortingEnabled?: boolean
	multiColumnSortingEnabled?: boolean
	renderAllRowKey?: string
}
const ImportPbcodesTable: React.FC<props> = ({
	columns,
	rows,
	headerClassName = "",
	headerRowClassName = "",
	tableClassName = "",
}) => {
	return (
		<table className={twMerge("whitespace-no-wrap w-full table-auto text-left", tableClassName)}>
			<thead className="sticky top-0 z-10 text-sm">
				<tr className={twMerge("bg-gray-3 h-9 text-left", headerRowClassName)}>
					{columns.map(({ label, key, keyForSorting = "", headerRender }, colIndex) => {
						const thClassName = cx({
							" bg-background px-2.5 py-1.25 text-sm font-semibold text-dark-alt text-left font-medium":
								true,
							"rounded-tl-md": colIndex === 0,
							"rounded-tr-2.5": colIndex === columns.length - 1,
						})

						return (
							<th key={colIndex} className={`${twMerge(thClassName)} ${headerClassName}`}>
								{headerRender && key ? headerRender({ label, key, keyForSorting }) : label}
							</th>
						)
					})}
				</tr>
			</thead>
			<tbody>
				{rows.length ? (
					rows.map((row: any, rowIndex: number) => {
						return (
							<tr
								key={rowIndex}
								className={`h-7 border-b text-sm font-normal`}
								data-index={rowIndex}
								tabIndex={0}
							>
								{columns.map(({ key, render, className = "" }, colIndex) => {
									return (
										<td
											key={`${rowIndex}_${colIndex}`}
											className={`pl-4 ${className} cursor-pointer ${
												row.isInvalidPin || row.isInvalidSin || row.isInvalidPbcode
													? "bg-red-400"
													: ""
											}`}
										>
											{render ? render({ row, key }) : row[key]}
										</td>
									)
								})}
							</tr>
						)
					})
				) : (
					<tr>
						<th colSpan={12} className="  p-3 text-center text-dark-alt">
							No Data
						</th>
					</tr>
				)}
			</tbody>
		</table>
	)
}

export default ImportPbcodesTable
