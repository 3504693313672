import React from "react"
import Icon from "./Icon"
import { TableColumnExtendedProps } from "./Table2"
import ImportPbcodesTable from "./ImportPbcodesTable"

interface Props {
	isLoading: boolean
	rows: any
	tableHeaders: TableColumnExtendedProps[]
}

const ImportPbcodesTableWrapper: React.FC<Props> = ({ rows, tableHeaders, isLoading }) => {
	return isLoading ? (
		<div className={`flex min-h-52 w-full max-w-3xl items-center justify-center  overflow-hidden`}>
			<Icon name="spinner" color="black" size={85} className=" animate-spin" />
		</div>
	) : (
		<div>
			<ImportPbcodesTable columns={tableHeaders} rows={rows} />
		</div>
	)
}

export default ImportPbcodesTableWrapper
