import { PayRollTermsInterface } from "@type/payBill.types"
import { GetPayGroupsInterface } from "@type/payGroup.types"
import { createContext } from "react"

export interface TermContextProps {
	handleRow: (e: GetPayGroupsInterface | null) => void
	payrollTerm: PayRollTermsInterface | null
	payrollTermsLoading: boolean
	payRollTerms: PayRollTermsInterface[]
}

const TermContext = createContext<TermContextProps | null>(null)

export default TermContext
