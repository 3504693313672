import { FeatureFlagEnums } from "types/featureFlags.type"
import { Permissions } from "config/constants"
import { caseConverters } from "config/utils"

enum FIELD_TYPE {
	RADIO = "RADIO",
	SELECT = "SELECT",
}
export enum ENTITIES {
	BRANCH = "BRANCH",
	SUPPLIER = "SUPPLIER",
	LOCATION = "LOCATION",
	EMPLOYEE = "EMPLOYEE",
	EMPLOYEES = "EMPLOYEES",
	PAY_GROUP = "PAY_GROUP",
	CUSTOMER = "CUSTOMER",
}

export enum DATE_PICKER {
	DEFAULT = "DEFAULT",
	MONTHLY = "MONTHLY",
}

export const COMMON_FIELDS = {
	BRANCH: { type: FIELD_TYPE.SELECT, name: ENTITIES.BRANCH, label: "Branch" } as Field,
	SUPPLIER: { type: FIELD_TYPE.SELECT, name: ENTITIES.SUPPLIER, label: "Supplier" } as Field,
	PAY_GROUP: { type: FIELD_TYPE.SELECT, name: ENTITIES.PAY_GROUP, label: "Pay Group" } as Field,
	LOCATION_DEPENDED_ON_BRANCH: {
		type: FIELD_TYPE.SELECT,
		name: ENTITIES.LOCATION,
		label: "Site",
		dependsOn: ENTITIES.BRANCH,
	} as Field,
	EMPLOYEE: { type: FIELD_TYPE.SELECT, name: ENTITIES.EMPLOYEES, label: "Employee" } as Field,
	EMPLOYEE_DEPENDED_ON_BRANCH: {
		type: FIELD_TYPE.SELECT,
		name: ENTITIES.EMPLOYEE,
		label: "Employee",
		dependsOn: ENTITIES.BRANCH,
	} as Field,
	EMPLOYEE_DEPENDED_ON_SUPPLIER: {
		type: FIELD_TYPE.SELECT,
		name: ENTITIES.EMPLOYEE,
		label: "Employee",
		dependsOn: ENTITIES.SUPPLIER,
	} as Field,
	CUSTOMER: {
		type: FIELD_TYPE.SELECT,
		name: ENTITIES.CUSTOMER,
		label: "Customer",
	} as Field,
	SELECTED_LOCATION_DEPENDED_ON_BRANCH: {
		type: FIELD_TYPE.SELECT,
		name: ENTITIES.LOCATION,
		label: "Site",
		dependsOn: ENTITIES.BRANCH,
		multiple: true,
	} as Field,
}

export interface Report {
	title: string
	name: REPORT_NAMES
	perform?: Permissions
	featureName: FeatureFlagEnums
	description: string
	radioButtons: ReportRadio[]
	datePicker: DATE_PICKER
}

export interface Field {
	type: FIELD_TYPE
	label: string
	name: ENTITIES
	dependsOn?: ENTITIES
	multiple?: boolean
}
export interface ReportRadio {
	title: string
	fields: Field[]
	additionalFields?: string[]
}
const groupBySupplier = "groupBySupplier"

export enum REPORT_NAMES {
	HOURS_SUMMARY_BY_EMPLOYEE = "HOURS_SUMMARY_BY_EMPLOYEE",
	HOURS_SUMMARY_BY_LOCATION = "HOURS_SUMMARY_BY_SITE",
	HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY = "HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY",
	TIME_SHEET_HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY = "TIME_SHEET_HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY",
	HOURS_WORKED_BY_LOCATION_DUTIES_ONLY = "HOURS_WORKED_BY_SITE_DUTIES_ONLY ",
	ACTUAL_HOURS_WORKED_BY_EMPLOYEE_DUTIES = "ACTUAL_HOURS_WORKED_BY_EMPLOYEE_DUTIES",
	ACTUAL_HOURS_WORKED_BY_LOCATION_DUTIES = "ACTUAL_HOURS_WORKED_BY_SITE_DUTIES",
	LOCATION_DISTRIBUTION_BY_SHIFTS = "SHIFT_DISTRIBUTION_BY_SITE",
	UNALLOCATED_DUTIES_BY_DATE = "UNALLOCATED_DUTIES_BY_DATE ",
	EMPLOYEES_DETAIL = "EMPLOYEES_DETAIL",
	CHECK_CALLS_BY_LOCATION = "SHIFTS_CHECK_CALLS_BY_SITE",
	MISSED_CHECK_CALLS_BY_LOCATION = "SHIFTS_MISSED_CHECK_CALLS_BY_SITE",
	LATE_BOOK_ONS_BY_LOCATION = "SHIFTS_LATE_BOOK_ONS_BY_SITE",
	QR_CALLS_BY_LOCATION = "SHIFTS_QR_CALLS_BY_SITE",
	INVENTORY_CALLS_BY_LOCATION = "SHIFTS_INVENTORY_CALLS_BY_SITE",
	ACTIVITY_REPORT_BY_LOCATION = "SHIFTS_ACTIVITY_REPORT_BY_SITE",
	SHIFT_INCIDENTS_SUMMARY_BY_SITE = "SHIFT_INCIDENTS_SUMMARY_BY_SITE",
	LOCATION_INCIDENT_REPORT_BY_LOCATION = "SITE_INCIDENT_REPORT",
	EMPLOYEES_BY_SIA_EXPIRY = "SIA_EXPIRY_BY_EMPLOYEE",
	HOURS_PAYCHECK_BY_EMPLOYEE = "HOURS_PAYCHECK_BY_EMPLOYEE",
	EMPLOYEE_START_DATES_SUMMARY = "EMPLOYEE_START_DATES_SUMMARY",
	EMPLOYEE_END_DATES_SUMMARY = "EMPLOYEE_END_DATES_SUMMARY",
	SHIFT_INCIDENT_BATCH_REPORT_BY_SITE = "SHIFT_INCIDENT_BATCH_REPORT_BY_SITE",
	PAYABLE_AMOUNT_DETAILS_BY_EMPLOYEE = "PAYABLE_AMOUNT_DETAILS_BY_EMPLOYEE",
	BILLABLE_AMOUNT_DETAILS_BY_SITE = "BILLABLE_AMOUNT_DETAILS_BY_SITE",
	BILLABLE_AMOUNT_SUMMARY_BY_SITE = "BILLABLE_AMOUNT_SUMMARY_BY_SITE",
	SITES_WITHOUT_ASSOCIATED_PB_CODE_BY_EMPLOYEE = "SITES_WITHOUT_ASSOCIATED_PB_CODE_BY_EMPLOYEE",
	SUPPLIER_PAYABLE_AMOUNT_SUMMARY_BY_SITE = "SUPPLIER_PAYABLE_AMOUNT_SUMMARY_BY_SITE",
}

export const reportNameToUrl = {
	[REPORT_NAMES.HOURS_SUMMARY_BY_EMPLOYEE as string]: "reports/employees/hours-summary-by-employee",
	[REPORT_NAMES.HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY as string]:
		"reports/employees/hours-worked-by-employee-duties-only",
	[REPORT_NAMES.ACTUAL_HOURS_WORKED_BY_EMPLOYEE_DUTIES as string]:
		"reports/employees/employee-worked-hours-by-employee-duties",
	[REPORT_NAMES.HOURS_SUMMARY_BY_LOCATION as string]: "reports/locations/hours-summary-by-location",
	[REPORT_NAMES.HOURS_WORKED_BY_LOCATION_DUTIES_ONLY as string]:
		"reports/locations/hours-worked-by-location-duties-only",
	[REPORT_NAMES.ACTUAL_HOURS_WORKED_BY_LOCATION_DUTIES as string]:
		"reports/locations/employee-worked-hours-by-location-duties",
	[REPORT_NAMES.UNALLOCATED_DUTIES_BY_DATE as string]: "reports/schedules/unallocated-duties-by-date",
	[REPORT_NAMES.EMPLOYEES_DETAIL as string]: "reports/employees/employees-details",
	[REPORT_NAMES.CHECK_CALLS_BY_LOCATION as string]: "reports/locations/check-calls-by-location",
	[REPORT_NAMES.MISSED_CHECK_CALLS_BY_LOCATION as string]: "reports/locations/missed-check-calls-by-location",
	[REPORT_NAMES.LATE_BOOK_ONS_BY_LOCATION as string]: "reports/locations/late-book-ons-by-location",
	[REPORT_NAMES.QR_CALLS_BY_LOCATION as string]: "reports/locations/qr-calls-by-location",
	[REPORT_NAMES.INVENTORY_CALLS_BY_LOCATION as string]: "reports/locations/inventory-calls-by-location",
	[REPORT_NAMES.ACTIVITY_REPORT_BY_LOCATION as string]: "reports/locations/all-calls-by-location",
	[REPORT_NAMES.LOCATION_DISTRIBUTION_BY_SHIFTS as string]: "reports/locations/shift-report-by-location",
	[REPORT_NAMES.SHIFT_INCIDENTS_SUMMARY_BY_SITE as string]: "reports/locations/shift-incidents-summary-by-location",
	[REPORT_NAMES.LOCATION_INCIDENT_REPORT_BY_LOCATION as string]: "reports/locations/incident-report-by-location",
	[REPORT_NAMES.EMPLOYEES_BY_SIA_EXPIRY as string]: "reports/employees/employees-by-sia-expiry",
	[REPORT_NAMES.HOURS_PAYCHECK_BY_EMPLOYEE as string]: "reports/employees/hours-paycheck-by-employee",
	[REPORT_NAMES.TIME_SHEET_HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY as string]:
		"reports/employees/time-sheet-hours-worked-by-employee-duties-only",
	[REPORT_NAMES.EMPLOYEE_START_DATES_SUMMARY as string]: "reports/employees/employees-details-by-service-start-date",
	[REPORT_NAMES.EMPLOYEE_END_DATES_SUMMARY as string]: "reports/employees/employees-details-by-service-end-date",
	[REPORT_NAMES.SHIFT_INCIDENT_BATCH_REPORT_BY_SITE as string]:
		"reports/locations/shift-incident-batch-report-by-location",
	[REPORT_NAMES.PAYABLE_AMOUNT_DETAILS_BY_EMPLOYEE as string]: "reports/employees/payable-amount-details-by-employee",
	[REPORT_NAMES.BILLABLE_AMOUNT_DETAILS_BY_SITE as string]: "reports/locations/billable-amount-details-by-location",
	[REPORT_NAMES.SITES_WITHOUT_ASSOCIATED_PB_CODE_BY_EMPLOYEE as string]:
		"reports/employees/sites-without-associated-pbcode-by-employee",
	[REPORT_NAMES.BILLABLE_AMOUNT_SUMMARY_BY_SITE as string]: "reports/locations/billable-amount-summary-by-location",
	[REPORT_NAMES.SUPPLIER_PAYABLE_AMOUNT_SUMMARY_BY_SITE as string]:
		"reports/locations/supplier-payable-amount-summary-by-location",
}

export const reports: Report[] = [
	{
		name: REPORT_NAMES.HOURS_SUMMARY_BY_EMPLOYEE,
		featureName: FeatureFlagEnums.REPORTS_HOURS_SUMMARY_BY_EMPLOYEE,
		title: caseConverters.titleCase(REPORT_NAMES.HOURS_SUMMARY_BY_EMPLOYEE),
		description:
			"List details for ALL live employee of the total hours worked, by eventtype during the period selected when thr report is run. The report is sorted by employee short name",
		radioButtons: [
			{
				title: "Employees",
				fields: [],
			},
			{
				title: "Employees within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Employees within the selected Supplier",
				fields: [COMMON_FIELDS.SUPPLIER],
			},
			{
				title: "Selected Employee within the Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Employee within the Supplier",
				fields: [COMMON_FIELDS.SUPPLIER, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_SUPPLIER],
			},
			{
				title: "All Employees within the selected Pay Group",
				fields: [COMMON_FIELDS.PAY_GROUP],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.HOURS_SUMMARY_BY_LOCATION,
		featureName: FeatureFlagEnums.REPORTS_HOURS_SUMMARY_BY_LOCATION,
		title: caseConverters.titleCase(REPORT_NAMES.HOURS_SUMMARY_BY_LOCATION),
		description:
			"Lists one summary of the hours scheduled against each site during the period selected when the report is run. The report is sorted by branch name and site name.",
		radioButtons: [
			{
				title: "All Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "All Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "All Sites within the selected Supplier",
				fields: [COMMON_FIELDS.SUPPLIER],
			},
			{
				title: "All Sites within All Suppliers",
				fields: [],
				additionalFields: [groupBySupplier],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY,
		featureName: FeatureFlagEnums.REPORTS_HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY,
		title: caseConverters.titleCase(REPORT_NAMES.HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY),
		description:
			"Lists the sites, events, scheduled for each employee during the period selected when the report run. The report is sorted by employee last name and event start time with a new page for each employee.",
		radioButtons: [
			{
				title: "Employees",
				fields: [],
			},
			{
				title: "Employees within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Employees within the selected Supplier",
				fields: [COMMON_FIELDS.SUPPLIER],
			},
			{
				title: "Selected Employee within the Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Employee within the Supplier",
				fields: [COMMON_FIELDS.SUPPLIER, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_SUPPLIER],
			},
			{
				title: "All Employees within the selected Pay Group",
				fields: [COMMON_FIELDS.PAY_GROUP],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.ACTUAL_HOURS_WORKED_BY_EMPLOYEE_DUTIES,
		featureName: FeatureFlagEnums.REPORTS_ACTUAL_HOURS_WORKED_BY_EMPLOYEE_DUTIES,
		title: caseConverters.titleCase(REPORT_NAMES.ACTUAL_HOURS_WORKED_BY_EMPLOYEE_DUTIES),
		description:
			"Lists the sites, events, scheduled for each employee during the period selected when the report run. The report is sorted by employee last name and event start time with a new page for each employee.",
		radioButtons: [
			{
				title: "Employees",
				fields: [],
			},
			{
				title: "Employees within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Employees within the selected Supplier",
				fields: [COMMON_FIELDS.SUPPLIER],
			},
			{
				title: "Selected Employee within the Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Employee within the Supplier",
				fields: [COMMON_FIELDS.SUPPLIER, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_SUPPLIER],
			},
			{
				title: "All Employees within the selected Pay Group",
				fields: [COMMON_FIELDS.PAY_GROUP],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.HOURS_WORKED_BY_LOCATION_DUTIES_ONLY,
		featureName: FeatureFlagEnums.REPORTS_HOURS_WORKED_BY_LOCATION_DUTIES_ONLY,
		title: caseConverters.titleCase(REPORT_NAMES.HOURS_WORKED_BY_LOCATION_DUTIES_ONLY),
		description:
			"Lists the ALL duties and employees scheduled against each site during the period selected when the report is run. The report is sorted by site name and event starttime with a new page for each site",
		radioButtons: [
			{
				title: "All Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "All Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "All Sites within the selected Supplier",
				fields: [COMMON_FIELDS.SUPPLIER],
			},
			{
				title: "All Sites within All Suppliers",
				fields: [],
				additionalFields: [groupBySupplier],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.ACTUAL_HOURS_WORKED_BY_LOCATION_DUTIES,
		featureName: FeatureFlagEnums.REPORTS_ACTUAL_HOURS_WORKED_BY_LOCATION_DUTIES,
		title: caseConverters.titleCase(REPORT_NAMES.ACTUAL_HOURS_WORKED_BY_LOCATION_DUTIES),
		description:
			"Lists the ALL duties and employees scheduled against each site during the period selected when the report is run. The report is sorted by site name and event starttime with a new page for each site",
		radioButtons: [
			{
				title: "Sites",
				fields: [],
			},
			{
				title: "Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.LOCATION_DISTRIBUTION_BY_SHIFTS,
		featureName: FeatureFlagEnums.REPORTS_LOCATION_DISTRIBUTION_BY_SHIFTS,
		title: caseConverters.titleCase(REPORT_NAMES.LOCATION_DISTRIBUTION_BY_SHIFTS),
		description: "Lists the Shifts Report against each site during the period selected when the report is run. ",
		radioButtons: [
			{
				title: "Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.UNALLOCATED_DUTIES_BY_DATE,
		featureName: FeatureFlagEnums.REPORTS_UNALLOCATED_DUTIES_BY_DATE,
		title: caseConverters.titleCase(REPORT_NAMES.UNALLOCATED_DUTIES_BY_DATE),
		description:
			"Lists details of all unallocated events that occur during the period selected when the report is run. The report is sorted by site name and event start date.",
		radioButtons: [
			{
				title: "All Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "All Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.EMPLOYEES_DETAIL,
		featureName: FeatureFlagEnums.REPORTS_EMPLOYEES_DETAIL,
		title: caseConverters.titleCase(REPORT_NAMES.EMPLOYEES_DETAIL),
		description: "Employees detail within the selected Branch.",
		radioButtons: [
			{
				title: "Employees within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Employees within the selected Pay Group",
				fields: [COMMON_FIELDS.PAY_GROUP],
			},
			{
				title: "Selected Employee within the Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Employee within the Supplier",
				fields: [COMMON_FIELDS.SUPPLIER, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_SUPPLIER],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.CHECK_CALLS_BY_LOCATION,
		featureName: FeatureFlagEnums.REPORTS_CHECK_CALLS_BY_LOCATION,
		title: caseConverters.titleCase(REPORT_NAMES.CHECK_CALLS_BY_LOCATION),
		description: "Lists the ALL Check Calls against each site during the period selected when the report is run. ",
		radioButtons: [
			{
				title: "Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.MISSED_CHECK_CALLS_BY_LOCATION,
		featureName: FeatureFlagEnums.REPORTS_CHECK_CALLS_BY_LOCATION,
		title: caseConverters.titleCase(REPORT_NAMES.MISSED_CHECK_CALLS_BY_LOCATION),
		description:
			"Lists all missed Check Calls against each site during the period selected when the report is run. ",
		radioButtons: [
			{
				title: "Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.LATE_BOOK_ONS_BY_LOCATION,
		featureName: FeatureFlagEnums.REPORTS_CHECK_CALLS_BY_LOCATION,
		title: caseConverters.titleCase(REPORT_NAMES.LATE_BOOK_ONS_BY_LOCATION),
		description: "Lists all late Book Ons against each site during the period selected when the report is run. ",
		radioButtons: [
			{
				title: "Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.INVENTORY_CALLS_BY_LOCATION,
		featureName: FeatureFlagEnums.REPORTS_INVENTORY_CALLS_BY_LOCATION,
		title: caseConverters.titleCase(REPORT_NAMES.INVENTORY_CALLS_BY_LOCATION),
		description:
			"Lists the ALL Inventory Calls against each site during the period selected when the report is run. ",
		radioButtons: [
			{
				title: "Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.QR_CALLS_BY_LOCATION,
		featureName: FeatureFlagEnums.REPORTS_QR_CALLS_BY_LOCATION,
		title: caseConverters.titleCase(REPORT_NAMES.QR_CALLS_BY_LOCATION),
		description: "Lists the ALL QR Scans against each site during the period selected when the report is run. ",
		radioButtons: [
			{
				title: "Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.ACTIVITY_REPORT_BY_LOCATION,
		featureName: FeatureFlagEnums.REPORTS_ACTIVITY_REPORT_BY_LOCATION,
		title: caseConverters.titleCase(REPORT_NAMES.ACTIVITY_REPORT_BY_LOCATION),
		description:
			"Lists the all the BookOns, BookOffs, Patrols, Check Calls and Inventory Submissions for each site during the period selected when the report is run. ",
		radioButtons: [
			{
				title: "Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.SHIFT_INCIDENTS_SUMMARY_BY_SITE,
		featureName: FeatureFlagEnums.REPORTS_SHIFT_INCIDENT_REPORT_BY_LOCATION,
		title: caseConverters.titleCase(REPORT_NAMES.SHIFT_INCIDENTS_SUMMARY_BY_SITE),
		description:
			"Lists the Incidents occurred during a Shift against each site during the period selected when the report is run. ",
		radioButtons: [
			{
				title: "Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.LOCATION_INCIDENT_REPORT_BY_LOCATION,
		featureName: FeatureFlagEnums.REPORTS_LOCATION_INCIDENT_REPORT_BY_LOCATION,
		title: caseConverters.titleCase(REPORT_NAMES.LOCATION_INCIDENT_REPORT_BY_LOCATION),
		description: "List Incidents occurred at selected Site during the selected Period.",
		radioButtons: [
			{
				title: "Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.TIME_SHEET_HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY,
		featureName: FeatureFlagEnums.REPORTS_TIME_SHEET_HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY,
		title: caseConverters.titleCase(REPORT_NAMES.TIME_SHEET_HOURS_WORKED_BY_EMPLOYEE_DUTIES_ONLY),
		description:
			"Lists the sites, scheduled for each employee (adjusted according to payroll hours) during the period selected when the report run.",
		radioButtons: [
			{
				title: "Employees",
				fields: [],
			},
			{
				title: "Employees within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Employees within the selected Supplier",
				fields: [COMMON_FIELDS.SUPPLIER],
			},
			{
				title: "Selected Employee within the Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Employee within the Supplier",
				fields: [COMMON_FIELDS.SUPPLIER, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_SUPPLIER],
			},
			{
				title: "All Employees within the selected Pay Group",
				fields: [COMMON_FIELDS.PAY_GROUP],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.EMPLOYEES_BY_SIA_EXPIRY,
		featureName: FeatureFlagEnums.REPORTS_EMPLOYEES_BY_SIA_EXPIRY,
		title: caseConverters.titleCase(REPORT_NAMES.EMPLOYEES_BY_SIA_EXPIRY),
		description: "List all employees with SIA Licenses expiring during the period selected when the report is run.",
		radioButtons: [
			{
				title: "Employees",
				fields: [],
			},
			{
				title: "Employees within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Employees within the selected Supplier",
				fields: [COMMON_FIELDS.SUPPLIER],
			},
			{
				title: "Selected Employee within the Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Employee within the Supplier",
				fields: [COMMON_FIELDS.SUPPLIER, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_SUPPLIER],
			},
			{
				title: "All Employees within the selected Pay Group",
				fields: [COMMON_FIELDS.PAY_GROUP],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.HOURS_PAYCHECK_BY_EMPLOYEE,
		featureName: FeatureFlagEnums.REPORTS_HOURS_PAYCHECK_BY_EMPLOYEE,
		title: caseConverters.titleCase(REPORT_NAMES.HOURS_PAYCHECK_BY_EMPLOYEE),
		perform: Permissions.VIEW_PAYROLL,
		description: "List payroll for selected employees for the total hours worked",
		radioButtons: [
			{
				title: "Employees",
				fields: [],
			},
			{
				title: "Employees within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Employees within the selected Supplier",
				fields: [COMMON_FIELDS.SUPPLIER],
			},
			{
				title: "Selected Employee within the Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Employee within the Supplier",
				fields: [COMMON_FIELDS.SUPPLIER, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_SUPPLIER],
			},
			{
				title: "All Employees within the selected Pay Group",
				fields: [COMMON_FIELDS.PAY_GROUP],
			},
		],
		datePicker: DATE_PICKER.MONTHLY,
	},
	{
		name: REPORT_NAMES.EMPLOYEE_START_DATES_SUMMARY,
		featureName: FeatureFlagEnums.REPORTS_EMPLOYEES_DETAIL,
		title: caseConverters.titleCase(REPORT_NAMES.EMPLOYEE_START_DATES_SUMMARY),
		description: "List employees starting their service during the period selected when the report is run.",
		radioButtons: [
			{
				title: "Employees",
				fields: [],
			},
			{
				title: "Employees within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Employees within the selected Supplier",
				fields: [COMMON_FIELDS.SUPPLIER],
			},
			{
				title: "Selected Employee within the Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Employee within the Supplier",
				fields: [COMMON_FIELDS.SUPPLIER, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_SUPPLIER],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.EMPLOYEE_END_DATES_SUMMARY,
		featureName: FeatureFlagEnums.REPORTS_EMPLOYEES_DETAIL,
		title: caseConverters.titleCase(REPORT_NAMES.EMPLOYEE_END_DATES_SUMMARY),
		description:
			"List employees whose service ended their service during the period selected when the report is run.",
		radioButtons: [
			{
				title: "Employees",
				fields: [],
			},
			{
				title: "Employees within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Employees within the selected Supplier",
				fields: [COMMON_FIELDS.SUPPLIER],
			},
			{
				title: "Selected Employee within the Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Employee within the Supplier",
				fields: [COMMON_FIELDS.SUPPLIER, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_SUPPLIER],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.SHIFT_INCIDENT_BATCH_REPORT_BY_SITE,
		featureName: FeatureFlagEnums.REPORTS_SHIFT_INCIDENT_REPORT_BY_LOCATION,
		title: caseConverters.titleCase(REPORT_NAMES.SHIFT_INCIDENT_BATCH_REPORT_BY_SITE),
		description:
			"Lists the Incidents occurred during a Shift against each site during the period selected when the report is run. ",
		radioButtons: [
			{
				title: "Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Site",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.PAYABLE_AMOUNT_DETAILS_BY_EMPLOYEE,
		featureName: FeatureFlagEnums.REPORTS_PAYABLE_AMOUNT_DETAILS_BY_EMPLOYEE,
		perform: Permissions.VIEW_PAYROLL,
		title: caseConverters.titleCase(REPORT_NAMES.PAYABLE_AMOUNT_DETAILS_BY_EMPLOYEE),
		description:
			"A detailed breakdown of the payable amounts owed to each employee for services rendered during the selected period.",
		radioButtons: [
			{
				title: "Employees",
				fields: [],
			},
			{
				title: "Employees within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Employee within the Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.BILLABLE_AMOUNT_DETAILS_BY_SITE,
		featureName: FeatureFlagEnums.REPORTS_BILLABLE_AMOUNT_DETAILS_BY_SITE,
		perform: Permissions.VIEW_PAYROLL,
		title: caseConverters.titleCase(REPORT_NAMES.BILLABLE_AMOUNT_DETAILS_BY_SITE),
		description: "A detailed overview of the billable amounts for each site during the selected period",
		radioButtons: [
			{
				title: "All Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "All Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "All Sites within the selected Supplier",
				fields: [COMMON_FIELDS.SUPPLIER],
			},
			{
				title: "All Sites within All Suppliers",
				fields: [],
				additionalFields: [groupBySupplier],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.SITES_WITHOUT_ASSOCIATED_PB_CODE_BY_EMPLOYEE,
		featureName: FeatureFlagEnums.REPORTS_SITES_WITHOUT_ASSOCIATED_PB_CODE_BY_EMPLOYEE,
		perform: Permissions.VIEW_PAYROLL,
		title: caseConverters.titleCase(REPORT_NAMES.SITES_WITHOUT_ASSOCIATED_PB_CODE_BY_EMPLOYEE),
		description:
			"Identifies sites that do not have an associated PB (paybill) code, categorized by employee. It highlights instances where employees are assigned to sites that lack the necessary PB code linkage, which may impact payroll processing and billing accuracy. ",
		radioButtons: [
			{
				title: "Employees",
				fields: [],
			},
			{
				title: "Employees within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "Selected Employee within the Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.EMPLOYEE_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.MONTHLY,
	},
	{
		name: REPORT_NAMES.BILLABLE_AMOUNT_SUMMARY_BY_SITE,
		featureName: FeatureFlagEnums.REPORTS_BILLABLE_AMOUNT_SUMMARY_BY_SITE,
		perform: Permissions.VIEW_PAYROLL,
		title: caseConverters.titleCase(REPORT_NAMES.BILLABLE_AMOUNT_SUMMARY_BY_SITE),
		description:
			"A summarized view of the total billable amounts for each site during the selected period. It captures the overall revenue generated by services performed at each location, offering a high-level financial overview for invoicing and revenue analysis.",
		radioButtons: [
			{
				title: "All Sites",
				fields: [],
			},
			{
				title: "All Sites for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "All Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "All Sites within the selected Supplier",
				fields: [COMMON_FIELDS.SUPPLIER],
			},
			{
				title: "All Sites within All Suppliers",
				fields: [],
				additionalFields: [groupBySupplier],
			},
			{
				title: "Selected Sites within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.SELECTED_LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
	{
		name: REPORT_NAMES.SUPPLIER_PAYABLE_AMOUNT_SUMMARY_BY_SITE,
		featureName: FeatureFlagEnums.REPORTS_SUPPLIER_PAYABLE_AMOUNT_SUMMARY_BY_SITE,
		perform: Permissions.VIEW_PAYROLL,
		title: caseConverters.titleCase(REPORT_NAMES.SUPPLIER_PAYABLE_AMOUNT_SUMMARY_BY_SITE),
		description:
			"Summarizes the total payable amounts to suppliers, organized by site. It provides a comprehensive view of the amounts owed for services rendered by suppliers at each location during the selected period.",
		radioButtons: [
			{
				title: "All Sites within All Suppliers",
				fields: [],
				additionalFields: [groupBySupplier],
			},
			{
				title: "All Sites within All Suppliers for selected Customer",
				fields: [COMMON_FIELDS.CUSTOMER],
			},
			{
				title: "All Sites within All Suppliers within the selected Branch",
				fields: [COMMON_FIELDS.BRANCH],
			},
			{
				title: "All Sites within the selected Supplier",
				fields: [COMMON_FIELDS.SUPPLIER],
			},
			{
				title: "Selected Site within All Suppliers",
				fields: [COMMON_FIELDS.BRANCH, COMMON_FIELDS.LOCATION_DEPENDED_ON_BRANCH],
			},
		],
		datePicker: DATE_PICKER.DEFAULT,
	},
].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

export const reportsToMask = [
	REPORT_NAMES.CHECK_CALLS_BY_LOCATION,
	REPORT_NAMES.INVENTORY_CALLS_BY_LOCATION,
	REPORT_NAMES.QR_CALLS_BY_LOCATION,
	REPORT_NAMES.ACTIVITY_REPORT_BY_LOCATION,
]
