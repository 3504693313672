import moment from "moment"
import AntdButton from "../../../components/AntdButton"
import { momentZ } from "../../../config/moment"
import useLocations from "../../../hooks/useLocations"
import FormSelectAntd from "../../../components/FormSelectAntd"
import useAppDispatch from "../../../hooks/useAppDispatch"
import useAppSelector from "../../../hooks/useAppSelector"
import { DatePicker } from "antd"
import { usePBcodes } from "../../../hooks/usePBcodes"
import { yupResolver } from "@hookform/resolvers/yup"
import { pbCodeDetailsSchema } from "Schema"
import { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { selectSelectedEmployee } from "../../../store/globalState.slice"
import { DATE_FORMATS, PBCodeOptions } from "../../../config/constants"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import { EmployeePaycodeDetailInterface } from "@type/employees.types"
import { FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form"
import {
	getPbCodeDetailsByEmployeeAndMonth,
	isEmployeePbCodeDetailsLoading,
	isEmployeePbCodeDetailsSaving,
	saveEmployeepbCodeDetailsData,
	selectEmployeePbCodeDetailsData,
} from "../../../store/Employee/PBCodeDetails/pbCodeDetails.slice"
import { getEmployeesWorkHistoryByEmployeeId } from "../../../store/Employee/Location/employeeWorkHistory.slice"
import useEmployeeWorkHistory from "../../../hooks/useEmployeeWorkHistory"
import Table from "../../../components/Table2"
import Icon from "../../../components/Icon"

interface EmployeePbCodeDetailsFormHandler extends EmployeePaycodeDetailInterface {}

const PBCodeDetails = () => {
	const dispatch = useAppDispatch()
	const isPbCodeDetailsSaving = useAppSelector(isEmployeePbCodeDetailsSaving())
	const isPbCodeDetailsLoading = useAppSelector(isEmployeePbCodeDetailsLoading())
	const employeePbCodeDetails = useAppSelector(selectEmployeePbCodeDetailsData())
	const selectedEmployee = useAppSelector(selectSelectedEmployee)
	const {
		employeeWorkHistoryForDuration,
		fetchEmployeeWorkHistoryForDuration,
		isEmployeeWorkHistoryForDurationLoading,
	} = useEmployeeWorkHistory()
	const { fetchPbCodesByUsedForFilter, isLoading, pbCodes } = usePBcodes()
	const { allLocations, fetchAllLocationsDetails, isAllLocationsLoading } = useLocations()
	const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(momentZ().endOf("day").endOf("month"))
	const [employeeSitesWithNoPbCode, setEmployeeSitesWithNoPbCode] =
		useState<{ _id: string; locationName: string }[]>()

	const employeeWorkedSites = useMemo(() => {
		if (selectedDate && selectedEmployee)
			return (
				employeeWorkHistoryForDuration.map(item => ({ _id: item.locationId, locationName: item.location })) ||
				[]
			)
		else return []
	}, [selectedDate, selectedEmployee, employeeWorkHistoryForDuration])

	const employeePbCodeDetailsMap = useMemo(() => {
		const pbCodesMap = new Map<string, string>()
		if (employeePbCodeDetails && employeePbCodeDetails.paycodes.length)
			employeePbCodeDetails.paycodes.forEach(item => pbCodesMap.set(item.location, item.pbcode))
		return pbCodesMap
	}, [employeePbCodeDetails])

	const methods = useForm<EmployeePbCodeDetailsFormHandler>({
		resolver: yupResolver(pbCodeDetailsSchema),
	})

	const {
		control,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = methods
	const { fields, append } = useFieldArray({
		control,
		name: "paycodes",
	})

	const formValues = useWatch({
		control,
		name: "paycodes",
	})

	const areValuesSameAsInitial = useMemo(() => {
		return (
			JSON.stringify(formValues) ===
			JSON.stringify(
				employeePbCodeDetails?.paycodes?.map(data => ({
					location: data.location,
					pbcode: data.pbcode,
				})),
			)
		)
	}, [formValues, employeePbCodeDetails])

	useEffect(() => {
		fetchPbCodesByUsedForFilter(PBCodeOptions.EMPLOYEE)
		fetchAllLocationsDetails()
	}, [])

	useLayoutEffect(() => {
		const employeeSitesWithNoPbCode =
			employeeWorkedSites.length && employeePbCodeDetails
				? employeeWorkedSites.filter(item => !employeePbCodeDetailsMap.has(item._id))
				: employeeWorkedSites
		setEmployeeSitesWithNoPbCode(employeeSitesWithNoPbCode)
	}, [employeeWorkedSites, employeePbCodeDetails, selectedDate])

	useEffect(() => {
		if (selectedEmployee && selectedDate) {
			const startDate = selectedDate.startOf("M").format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1)
			const endDate = selectedDate.endOf("M").format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1)
			fetchEmployeeWorkHistoryForDuration(selectedEmployee, startDate, endDate)
		}
	}, [selectedEmployee, selectedDate])

	useEffect(() => {
		if (selectedEmployee && selectedDate) {
			dispatch(
				getPbCodeDetailsByEmployeeAndMonth(
					selectedEmployee,
					selectedDate.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
				),
			)
			dispatch(getEmployeesWorkHistoryByEmployeeId(selectedEmployee))
		}
	}, [selectedEmployee, selectedDate])

	useEffect(() => {
		if (employeePbCodeDetails) {
			setValue(
				"paycodes",
				employeePbCodeDetails.paycodes.map(data => ({ location: data.location, pbcode: data.pbcode })),
			)
			setValue("_id", employeePbCodeDetails._id)
		} else {
			reset()
		}
	}, [employeePbCodeDetails, selectedEmployee, selectedDate])

	const onSubmit: SubmitHandler<EmployeePbCodeDetailsFormHandler> = data => {
		if (selectedDate)
			dispatch(
				saveEmployeepbCodeDetailsData({
					...data,
					employee: selectedEmployee as string,
					month: selectedDate.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
				}),
			)
	}

	const handlePrev = () => {
		if (selectedDate) setSelectedDate(momentZ(selectedDate).subtract(1, "month").endOf("day").endOf("month"))
		else setSelectedDate(momentZ().subtract(1, "month").endOf("day").endOf("month"))
	}

	const handleNext = () => {
		if (selectedDate) setSelectedDate(momentZ(selectedDate).add(1, "month").endOf("day").endOf("month"))
		else setSelectedDate(momentZ().add(1, "month").endOf("day").endOf("month"))
	}

	if (isAllLocationsLoading || isLoading || isEmployeeWorkHistoryForDurationLoading) {
		return (
			<div className="flex h-full w-full items-center justify-center bg-white">
				<Icon name="spinner" size={30} color="black" className="animate-spin" />
			</div>
		)
	}

	return (
		<div>
			<div className="mb-3 flex w-1/3 items-center space-x-2">
				<AntdButton onClick={handlePrev} shape="circle" icon={<MdChevronLeft size={15} />} />
				<DatePicker
					style={{ border: "solid slate", height: "35px", minWidth: "280px" }}
					picker="month"
					placeholder="Select Month"
					value={selectedDate}
					disabledDate={current => {
						return current && current > momentZ().endOf("month")
					}}
					onChange={value => {
						if (value) setSelectedDate(momentZ(value).endOf("day").endOf("month"))
						else setSelectedDate(null)
					}}
				/>
				<AntdButton
					onClick={handleNext}
					disabled={(selectedDate && selectedDate.isSame(momentZ(), "month")) ?? true}
					shape="circle"
					icon={<MdChevronRight size={15} />}
				></AntdButton>
			</div>

			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex flex-row justify-between">
						<div className="w-1/2">
							<div className="mb-2 text-4 font-bold">Sites With Associated PB Code</div>
							<ul className="no-scrollbar h-[calc(100vh_-_320px)] overflow-y-scroll p-2">
								{fields.map((item, index) => (
									<li key={`${item.id}${momentZ().unix()}`}>
										<div className="grid grid-cols-2 gap-y-4 gap-x-5">
											<div>
												<FormSelectAntd
													disabled={!!item.id}
													label="Site"
													name={`paycodes.${index}.location`}
													options={[
														{ label: "Select Site", value: "" },
														...allLocations.map(({ _id, name }) => ({
															label: name,
															value: _id,
														})),
													]}
												/>
												<div className="text-red-800">
													{errors.paycodes && errors.paycodes[index]?.location?.message}
												</div>
											</div>

											<div>
												<FormSelectAntd
													disabled={!pbCodes.length}
													label="PB code"
													name={`paycodes.${index}.pbcode`}
													options={[
														{ label: "Select PbCode", value: "" },
														...pbCodes.map(({ _id, name }) => ({
															label: name,
															value: _id,
														})),
													]}
												/>
												<div className="text-red-800">
													{errors.paycodes && errors.paycodes[index]?.pbcode?.message}
												</div>
											</div>
										</div>
									</li>
								))}
							</ul>
						</div>

						<div className="">
							<div className="mb-2 ml-auto text-4 font-bold">Sites Without Associated PB Code</div>
							<div className="no-scrollbar col-span-6 mt-1.25 h-[calc(100vh_-_320px)] overflow-y-scroll">
								<Table
									rows={employeeSitesWithNoPbCode ? employeeSitesWithNoPbCode : []}
									columns={[
										{
											label: "Name",
											key: "locationName",
											render: ({ row, key }) => <div className="text-left">{row[key]}</div>,
										},
										{
											label: "Actions",
											key: "_id",
											render: ({ row, key }) => {
												return (
													<AntdButton
														onClick={() => {
															append({
																location: row[key],
																pbcode: "",
															})
															if (employeeSitesWithNoPbCode) {
																setEmployeeSitesWithNoPbCode(
																	employeeSitesWithNoPbCode?.filter(
																		item => item._id !== row[key],
																	),
																)
															}
														}}
													>
														Add
													</AntdButton>
												)
											},
										},
									]}
								/>
							</div>
						</div>
					</div>

					<div className="w-1/4">
						<div className="grid grid-cols-2 gap-x-5 gap-y-5">
							<AntdButton
								disabled={
									!!Object.keys(errors).length ||
									!pbCodes.length ||
									!allLocations.length ||
									isLoading ||
									isAllLocationsLoading ||
									isPbCodeDetailsSaving ||
									isPbCodeDetailsLoading ||
									!methods.formState.isDirty ||
									areValuesSameAsInitial
								}
								loading={isPbCodeDetailsSaving}
								htmlType="submit"
							>
								Save
							</AntdButton>
						</div>
					</div>
				</form>
			</FormProvider>
		</div>
	)
}

export default PBCodeDetails
