import React, { useEffect, useMemo } from "react"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAppDispatch, useAppSelector, useFeatureFlags } from "hooks"
import { AntdButton, AntdSelect1, DateField, FormCheckbox, FormInputAntd, FormSelectAntd } from "components"
import FormSkeleton from "components/FormSkeleton"
import {
	getLocationContractsByLocationId,
	isLocationContractsLoading,
	isLocationContractsSaving,
	saveLocationContracts,
	selectLocationContractsData,
} from "store/Location/locationContracts.slice"
import { LocationSchema } from "Schema"
import { GetLocationContractsInterface } from "@type/locations.types"
import { convertIOSDateToHTMLFormat } from "config/utils"
import { Ongoing, PBCodeOptions, Permissions } from "config/constants"
import { AllowedTo } from "react-abac"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { selectSelectedLocation } from "store/globalState.slice"
import { usePBcodes } from "../../../hooks/usePBcodes"
import { FeatureFlagEnums } from "../../../types/featureFlags.type"

const { contractValidationSchema, contractInitialValues } = LocationSchema

const Contract: React.FC = () => {
	const dispatch = useAppDispatch()
	const selected = useAppSelector(selectSelectedLocation)
	const { featureFlagEnabled } = useFeatureFlags()
	const contractLoading = useAppSelector(isLocationContractsLoading())
	const contracts = useAppSelector(selectLocationContractsData())
	const saving = useAppSelector(isLocationContractsSaving())

	const { fetchPbCodesByUsedForFilter, isLoading: isPbCodesLoading, pbCodes } = usePBcodes()

	const methods = useForm<GetLocationContractsInterface>({ resolver: yupResolver(contractValidationSchema) })
	const {
		handleSubmit,
		reset,
		setValue,
		watch,
		formState: { isDirty, isSubmitting, errors },
	} = methods

	const contract = useMemo(() => {
		return contracts.find(({ _id }) => _id === watch("_id"))
	}, [watch("_id"), contracts])

	useEffect(() => {
		fetchPbCodesByUsedForFilter(PBCodeOptions.CONTRACT)
	}, [])

	useEffect(() => {
		if (selected) {
			dispatch(getLocationContractsByLocationId(selected))
			setValue("_id", contracts.length ? contracts[0]._id : "")
		}
	}, [selected])

	useEffect(() => {
		if (contract?._id) reset({ ...contractInitialValues, ...contract })
		else reset({ ...contractInitialValues })
	}, [contract])

	const onSubmit: SubmitHandler<GetLocationContractsInterface> = data =>
		dispatch(saveLocationContracts({ ...data, location: selected }))

	if (contractLoading || isPbCodesLoading) return <FormSkeleton />

	return (
		<div className="my-2 flex flex-col">
			<div className="mt-2 ml-2">
				<AuditTrail id={contract?._id} name={Entities.LOCATION_CONTRACTS} />
			</div>

			<div className="mt-4 flex flex-col">
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="space-y-4">
							<div className="grid grid-cols-4 items-center gap-y-2 gap-x-3">
								<div className="col-span-4 w-1/4">
									<AntdSelect1
										label="Contracts"
										onChange={value => {
											if (value === "") reset({ ...contractInitialValues })
											else setValue("_id", value)
										}}
										value={watch("_id")}
										options={[
											{ label: "Create Contract", value: "" },
											...contracts.map(({ startDate, finishDate, _id }) => ({
												label: finishDate
													? `${convertIOSDateToHTMLFormat(
															startDate,
													  )}-${convertIOSDateToHTMLFormat(finishDate)}`
													: `${convertIOSDateToHTMLFormat(startDate)}- ${Ongoing}`,
												value: _id,
											})),
										]}
									/>
								</div>
								<FormInputAntd type="text" label="Reference" name="reference" />
								{featureFlagEnabled(FeatureFlagEnums.LOCATIONS_CONTRACTS_PB_CODE) && (
									<AllowedTo
										yes={() => (
											<FormSelectAntd
												disabled={!pbCodes.length}
												label="PB Code"
												name="PBCode"
												options={[
													{ label: "Select PbCode", value: "" },
													...pbCodes.map(({ _id, name }) => ({
														label: name,
														value: _id,
													})),
												]}
											/>
										)}
										perform={Permissions.VIEW_PAYROLL}
									/>
								)}

								<DateField
									label="Start Date"
									name={"startDate"}
									setValue={setValue}
									watch={watch}
									errors={errors}
								/>
								<DateField
									label="Finish Date"
									name={"finishDate"}
									setValue={setValue}
									watch={watch}
									errors={errors}
								/>
								<FormInputAntd type="number" min={0} label="Hours Per Week" name="hoursPerWeek" />
								<FormInputAntd type="number" min={0} label="Weeks In Roster" name="weeksInRoster" />
								<FormInputAntd type="number" min={0} label="VAT Rating" name="vatRating" />
								<FormInputAntd type="text" label="Service Type" name="serviceType" />
								<FormInputAntd type="textarea" label="Service Description" name="serviceDescription" />
								<FormInputAntd type="text" label="Payment Terms" name="paymentTerms" />
								<FormCheckbox label="Auto Generate Enabled" name="autoGenerateEnabled" />
							</div>
							<AllowedTo perform={Permissions.UPDATE_LOCATION_CONTRACTS}>
								<div className="flex gap-3">
									<AntdButton loading={saving} disabled={!isDirty || isSubmitting} htmlType="submit">
										{contract ? "Update" : "Save"}
									</AntdButton>
									<AntdButton
										onClick={e => {
											e.preventDefault()
											reset(contractInitialValues)
										}}
										htmlType="submit"
									>
										Clear
									</AntdButton>
								</div>
							</AllowedTo>
						</div>
					</form>
				</FormProvider>
			</div>
		</div>
	)
}

export default Contract
