import axios from "axios"
import { EmployeeWorkHistoryInterface } from "@type/employees.types"
const ROOT_PATH = "employees/work-history"

export default class EmployeesWorkHistoryService {
	static getEmployeeWorkHistoryByEmployeeID = (employeeId: string) =>
		axios.get<EmployeeWorkHistoryInterface[]>(`${ROOT_PATH}/${employeeId}`)

	static getEmployeeWorkHistoryForDurationByEmployee = (employeeId: string, startDate: string, endDate: string) =>
		axios.get<EmployeeWorkHistoryInterface[]>(`${ROOT_PATH}/for-duration/${employeeId}`, {
			params: {
				startDate,
				endDate,
			},
		})
}
